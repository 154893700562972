<template>
  <b-navbar>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="@/assets/appbi.png" alt="APPBI" />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item
        v-if="currentUser"
        tag="router-link"
        :to="{ path: '/home' }"
      >
        Home
      </b-navbar-item>
      <b-navbar-item
        v-if="currentUser"
        tag="router-link"
        :to="{ path: '/anggota' }"
      >
        List Anggota
      </b-navbar-item>
      <b-navbar-item
        v-if="currentUser"
        tag="router-link"
        :to="{ path: '/setting' }"
      >
        Pengaturan
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/home' }">
        Kartu Anggota
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-navbar-item tag="div">
        <div class="buttons">
          <div v-if="currentUser" class="buttons">
            <b-button @click="logout" type="is-light"> Log Out </b-button>
          </div>
          <div v-else class="buttons">
            <b-butto
              v-if="!currentUser"
              tag="router-link"
              :to="{ path: '/register' }"
              class="button is-primary"
            >
              Sign up
            </b-butto>
            <b-button tag="router-link" to="/login" type="is-light">
              Log in
            </b-button>
          </div>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["currentUser"]),
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  data() {
    return {
      isPublic: true,
    };
  },
};
</script>

<style></style>
