<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-3">
            <form @submit.prevent="registerUser">
              <b-field label="Name">
                <b-input v-model="register.name" type="text"> </b-input>
              </b-field>
              <b-field label="Email">
                <b-input
                  v-model="register.email"
                  type="email"
                  value="john@"
                  maxlength="30"
                >
                </b-input>
              </b-field>
              <b-field label="Password">
                <b-input
                  v-model="register.password"
                  type="password"
                  placeholder="Password reveal input"
                  password-reveal
                >
                </b-input>
              </b-field>
              <b-button @click="registerUser" expanded>Register</b-button>
            </form>
          </div>
        </div>

        <!-- <b-button @click="authenticate('instagram')" icon-left="instagram">
          Login with Instagram
        </b-button> -->
      </div>
    </div>
  </section>
</template>

<script>
// import axios from "axios";
export default {
  name: "Register",
  data() {
    return {
      register: {
        name: "",
        email: "",
        admin: false,
        password: "",
      },
    };
  },
  methods: {
    async registerUser() {
      this.$store
        .dispatch("register", this.register)
        .then(() => {
          this.$buefy.snackbar.open({
            message: "Success Register!",
            type: "is-warning",
            position: "is-top",
            indefinite: true,
          });
          this.$router.push("/");
        })
        .catch((err) =>
          this.$buefy.snackbar.open({
            message: err.message,
            type: "is-danger",
            position: "is-top",
            indefinite: true,
          })
        );
    },
  },
};
</script>
