<template>
  <div class="home">
    <section class="hero is-fullheight-with-navbar">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-4">
              <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                filename="APBBI"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a7"
                pdf-orientation="landscape"
                pdf-content-width="800px"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
              >
                <section slot="pdf-content">
                  <div>
                    <div
                      class="card"
                      :style="{
                        height: '279px',
                        width: '400px',
                        'background-image': 'url(' + anggota.preview + ')',
                        'background-size': '400px'
                      }"
                    >
                      <div class="card-content">
                        <div class="media">
                          <div class="media-content">
                            <div style="padding-left: 5px; padding-top: 140px">
                              <p class="subtitle is-6">
                                <b>{{ anggota.name }}</b
                                ><br />
                                APPBI{{ anggota.kecamatan }}{{ anggota.status
                                }}{{ anggota.nomor }}
                              </p>
                            </div>
                            <div style="padding-left: 40px; padding-top: 15px">
                              <p class="subtitle is-6" style="font-size: 10px">
                                <b>{{
                                  new Date(anggota.berlaku).toLocaleDateString()
                                }}</b>
                              </p>
                            </div>
                            <b-image
                              style="
                                position: absolute;
                                top: 85px;
                                left: 288px;
                                width: 95px;
                              "
                              :src="anggota.profile"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </vue-html2pdf>

              <div class="columns is-multiline pt-2">
                <div
                  class="column is-2 has-text-centered pl-2"
                  v-for="item in images"
                  :key="item._id"
                >
                  <b-image
                    :src="item.files"
                    alt="A random image"
                    class="is-64x64 mb-2"
                  ></b-image>
                  <b-button @click="setImage(item.files)">Pick</b-button>
                </div>
              </div>

              <div class="columns" v-if="anggota.berlaku != null">
                <div class="column">
                  <b-button
                    class="mt-4"
                    type="is-primary"
                    @click="saveUser()"
                    expanded
                    >Save</b-button
                  >
                  <!-- <b-button
                    class="mt-4"
                    type="is-primary"
                    @click="generateReport()"
                    expanded
                    >Print PDF</b-button
                  > -->
                </div>
              </div>
            </div>
            <div class="column is-4">
              <b-field label="Nama Lengkap">
                <b-input v-model="anggota.name"></b-input>
              </b-field>

              <b-field label="Provinsi">
                <b-select
                  v-model="anggota.provinsi"
                  placeholder="Provinsi"
                  @change.native="getKabupaten($event)"
                  expanded
                >
                  <option
                    v-for="option in Provinsi"
                    :value="option.id"
                    :key="option.id"
                  >
                    {{ option.nama }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Kabupaten">
                <b-select
                  v-model="anggota.kabupaten"
                  placeholder="Kabupaten"
                  @change.native="getKecamatan($event)"
                  expanded
                >
                  <option
                    v-for="option in Kabupaten"
                    :value="option.id"
                    :key="option.id"
                  >
                    {{ option.nama }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Kecamatan">
                <b-select
                  v-model="anggota.kecamatan"
                  placeholder="Kecamatan"
                  expanded
                >
                  <option
                    v-for="option in Kecamatan"
                    :value="option.id"
                    :key="option.id"
                  >
                    {{ option.nama }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Status">
                <b-select
                  v-model="anggota.status"
                  placeholder="Status"
                  expanded
                >
                  <option value="1">Pendiri</option>
                  <option value="2">Anggota</option>
                </b-select>
              </b-field>

              <b-field>
                <b-field
                  message="Ini adalah nomor unik untuk setiap anggota"
                  label="Nomor Urut (6 Digit)"
                >
                  <b-input
                    disabled
                    v-model="anggota.nomor"
                    placeholder="Nomor Urut (6 Digit)"
                  />
                </b-field>
              </b-field>
              <b-field label="Masa Berlaku">
                <b-datepicker
                  v-model="anggota.berlaku"
                  placeholder="Click to select..."
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>

              <form @submit.prevent="handleSubmit">
                <section>
                  <b-field>
                    <b-upload v-model="dropFiles" multiple drag-drop expanded>
                      <section class="section">
                        <div class="content has-text-centered">
                          <p>
                            <b-icon icon="upload" size="is-large"> </b-icon>
                          </p>
                          <p>Drop your files here or click to upload</p>
                        </div>
                      </section>
                    </b-upload>
                  </b-field>

                  <div class="tags">
                    <span
                      v-for="(file, index) in dropFiles"
                      :key="index"
                      class="tag is-primary"
                    >
                      {{ file.name }}
                      <button
                        class="delete is-small"
                        type="button"
                        @click="deleteDropFile(index)"
                      ></button>
                    </span>
                  </div>

                  <b-button @click="handleSubmit" expanded
                    >Upload Photo</b-button
                  >
                </section>
              </form>
              <hr />

              <b-field label="Tanggal Lahir">
                <b-datepicker
                  v-model="anggota.tgllahir"
                  placeholder="Click to select..."
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>

              <b-field label="Agama">
                <b-select v-model="anggota.agama" placeholder="Agama" expanded>
                  <option value="Islam">Islam</option>
                  <option value="Kristen">Kristen</option>
                  <option value="Katolik">Katolik</option>
                  <option value="Hindu">Hindu</option>
                  <option value="Budha">Budha</option>
                </b-select>
              </b-field>

              <b-field label="Nomor Handphone">
                <b-input
                  v-model="anggota.phone"
                  placeholder="Phone number"
                ></b-input>
              </b-field>

              <b-field label="Email">
                <b-input
                  type="email"
                  v-model="anggota.email"
                  placeholder="Email"
                ></b-input>
              </b-field>

              <b-field label="Asosiasi/Paguyuban yang pernah diikuti">
                <b-input v-model="anggota.asosiasi"></b-input>
              </b-field>

              <b-field label="Nama Perusahaan">
                <b-input
                  v-model="anggota.perusahaan"
                  placeholder="Company"
                ></b-input>
              </b-field>

              <b-field label="Tahun Berdiri">
                <b-input
                  v-model="anggota.tahunberdiri"
                  placeholder="Since"
                ></b-input>
              </b-field>

              <b-field label="Alamat Perusahaan">
                <b-input
                  type="textarea"
                  v-model="anggota.alamatperusahaan"
                  placeholder="Company Address"
                ></b-input>
              </b-field>

              <b-field label="Jumlah Pegawai">
                <b-numberinput
                  v-model="anggota.jmlpegawai"
                  placeholder="4"
                  :min="1"
                ></b-numberinput>
              </b-field>

              <b-field label="Jenis Produksi">
                <b-select v-model="anggota.produksi" expanded>
                  <option value="Batik Tulis">Batik Tulis</option>
                  <option value="Batik Cap">Batik Cap</option>
                  <option value="Keduanya">Keduanya</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Provinsi from "../assets/provinsi.json";
import VueHtml2pdf from "vue-html2pdf";
export default {
  name: "Home",
  components: {
    VueHtml2pdf
  },
  data() {
    return {
      dropFiles: null,
      images: [],
      Provinsi,
      Kabupaten: [],
      Kecamatan: [],
      anggota: {
        name: null,
        preview: null,
        profile: null,
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        status: null,
        berlaku: null,
        nomor: null,
        urut: null,
        tgllahir: null,
        agama: null,
        perusahaan: null,
        alamatperusahaan: null,
        phone: null,
        email: null,
        jmlpegawai: null,
        produksi: null,
        tahunberdiri: null,
        asosiasi: null
      }
    };
  },

  mounted() {
    axios.get("http://localhost:3000/images").then(res => {
      this.images = res.data;
      if (res.data.length >= 1) {
        this.anggota.preview = res.data[0].files;
      }
    });
    this.getNomor();
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    getNomor() {
      axios.get("http://localhost:3000/number").then(res => {
        console.log("Nomor");
        console.log(res.data);
        if (res.data.length >= 1) {
          this.anggota.urut = res.data[0].noUrut + 1;
          if (res.data[0].noUrut.toString().length == 1) {
            this.anggota.nomor = "00000" + (res.data[0].noUrut + 1);
          } else if (res.data[0].noUrut.toString().length == 2) {
            this.anggota.nomor = "0000" + (res.data[0].noUrut + 1);
          } else if (res.data[0].noUrut.toString().length == 3) {
            this.anggota.nomor = "000" + (res.data[0].noUrut + 1);
          } else if (res.data[0].noUrut.toString().length == 4) {
            this.anggota.nomor = "00" + (res.data[0].noUrut + 1);
          } else if (res.data[0].noUrut.toString().length == 5) {
            this.anggota.nomor = "0" + (res.data[0].noUrut + 1);
          } else {
            this.anggota.nomor = res.data[0].noUrut + 1;
          }
        } else {
          this.anggota.urut = 1;
          this.anggota.nomor = "000001";
        }
      });
    },
    handleSubmit() {
      const formData = new FormData();
      for (const i of Object.keys(this.dropFiles)) {
        formData.append("files", this.dropFiles[i]);
      }
      axios.post("http://localhost:3000/file-photo", formData, {}).then(res => {
        this.anggota.profile = res.data.userCreated.files;
        this.$buefy.toast.open({
          message: res.data.message,
          type: "is-success"
        });
      });
    },
    saveUser() {
      axios
        .post("http://localhost:3000/user", this.anggota)
        .then(res => {
          console.log("saved");
          this.$buefy.snackbar.open({
            message: res.data.message + " Ingin Menginputkan Data Baru",
            type: "is-success",
            position: "is-top",
            actionText: "Ya",
            indefinite: true,
            onAction: () => {
              this.toClear();
            }
          });
          axios
            .post("http://localhost:3000/number", { nomor: this.anggota.nomor })
            .then(res => {
              console.log(res.data);
            });
          this.generateReport();
        })
        .catch(err => {
          console.log("err");
          console.log(err);
          this.$buefy.snackbar.open({
            message: err,
            type: "is-danger",
            position: "is-top",
            actionText: "OK",
            indefinite: true,
            onAction: () => {}
          });
        });
    },
    toClear() {
      this.anggota.name = null;
      this.anggota.provinsi = null;
      this.anggota.kabupaten = null;
      this.anggota.kecamatan = null;
      this.anggota.status = null;
      this.anggota.berlaku = null;
      this.anggota.profile = null;
      this.dropFiles = null;

      axios.get("http://localhost:3000/number").then(res => {
        console.log("Nomor");
        console.log(res.data);
        if (res.data.length >= 1) {
          this.anggota.urut = res.data[0].noUrut + 1;
          if (res.data[0].noUrut.toString().length == 1) {
            this.anggota.nomor = "00000" + (res.data[0].noUrut + 1);
          } else if (res.data[0].noUrut.toString().length == 2) {
            this.anggota.nomor = "0000" + (res.data[0].noUrut + 1);
          } else if (res.data[0].noUrut.toString().length == 3) {
            this.anggota.nomor = "000" + (res.data[0].noUrut + 1);
          } else if (res.data[0].noUrut.toString().length == 4) {
            this.anggota.nomor = "00" + (res.data[0].noUrut + 1);
          } else if (res.data[0].noUrut.toString().length == 5) {
            this.anggota.nomor = "0" + (res.data[0].noUrut + 1);
          } else {
            this.anggota.nomor = res.data[0].noUrut + 1;
          }
        } else {
          this.anggota.urut = 1;
          this.anggota.nomor = "000001";
        }
      });
    },
    getKabupaten(event) {
      var link = "../assets/kabupaten/" + event.target.value + ".json";
      axios.get(link).then(
        response => {
          this.Kabupaten = response.data;
        },
        function(error) {
          console.log(error.statusText);
        }
      );
    },
    getKecamatan(event) {
      var link = "../assets/kecamatan/" + event.target.value + ".json";
      axios.get(link).then(
        response => {
          this.Kecamatan = response.data;
        },
        function(error) {
          console.log(error.statusText);
        }
      );
    },
    setImage(value) {
      this.anggota.preview = value;
    }
  }
};
</script>
