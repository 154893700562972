import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import First from "../views/First.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "First",
    component: First
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/anggota",
    name: "Anggota",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Anggota.vue")
  },
  {
    path: "/detail/:id",
    name: "Detail",
    // props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Detail.vue")
  },
  {
    path: "/print/:id",
    name: "Print",
    // props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Print.vue")
  },
  {
    path: "/setting",
    name: "Setting",
    meta: {
      requiresAuth: true
    },
    component: () => import("../views/Setting.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const currentUser = store.state.currentUser;
  if (requiresAuth && !currentUser) {
    next("/");
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
